<template>
  <el-dialog
    id="SelectCorpDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="40%"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @keydown.enter.native="onFormEnter"
    destroy-on-close
  >
    <el-container v-loading="loading">
      <el-header class="header3" height="48"
        ><el-row type="flex" justify="start">
          <div>
            <el-input
              :placeholder="$t('Пошук')"
              style="width:308px;"
              prefix-icon="el-icon-search"
              clearable
              v-model="filterString"
              ref="filter"
              @keydown.esc.native="filterString = ''"
            />
          </div> </el-row
      ></el-header>
      <div ref="flex_container" class="flex_container">
        <ag-grid-vue
          ref="selectCorpTable"
          id="selectCorpTable"
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          rowSelection="single"
          :defaultColDef="defaultColDef"
          :columnDefs="columns"
          :headerHeight="32"
          :rowData="corps"
          :gridOptions="gridOptions"
          @selection-changed="onGridSelectionChanged"
          :overlayNoRowsTemplate="noRowsToShowTemplate"
          @gridReady="onGridReady"
          @sortChanged="onGridColumnsChange"
          @columnResized="onGridColumnsChange"
          @columnMoved="onGridColumnsChange"
          @displayedColumnsChanged="onGridColumnsChange"
          :modules="modules"
          :localeTextFunc="gridLocale"
          :enableBrowserTooltips="true"
        >
        </ag-grid-vue>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button" :disabled="!selectedCorp">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="onCancel" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import store from "@/store";
  import { _ } from "vue-underscore";
  import { bus } from "@/main";
  import { AgGridVue } from "@ag-grid-community/vue";
  import { AllModules } from "@ag-grid-enterprise/all-modules";

  function parseError(error) {
    if (typeof error == "string") {
      return error;
    } else if (Array.isArray(error)) {
      return error.join("; ");
    } else return error;
  }

  export default {
    name: "SelectCorp",
    components: { AgGridVue },
    data() {
      return {
        loading: false,
        filterString: "",
        modules: AllModules,
        dialogShow: false,
        dialogHeight: 0,
        resizeObserver: null,
        title: $tt("Пошук"),
        target: null,
        model: {},
        initModel: {},
        selectedCorp: null,
        waitForAnswer: false,
        keyUpTimer: null,
        filterHasChanged: false,
        gridApi: null,
        gridColumnApi: null,
        noRowsToShowTemplate: `<div><img src="${require("../../assets/icons/no_data.svg")}"></div>`,
        gridOptions: {
          suppressCellSelection: true,
          getRowNodeId: function(data) {
            return data.id_corp;
          },
          immutableData: true,
          rowHeight: 32,
          floatingFiltersHeight: 32,
        },

        defaultColDef: {
          suppressNavigable: true,
          cellClass: "no-border",
          resizable: true,
          sortable: true,
          tooltipValueGetter: function(params) {
            return params.value;
          },
          floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
          suppressMenu: false,
          filter: "agTextColumnFilter",
          floatingFilterComponentParams: { suppressFilterButton: true },
        },
        columns: [
          {
            headerCheckboxSelection: false,
            checkboxSelection: true,
            headerName: $tt("Назва"),
            field: "corp_name",
            flex: 1,
          },
          //{
          //   headerName: "AxiomaID",
          //   field: "id_morion",
          //   width: 120,
          // },
        ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
      };
    },
    created() {
      var vm = this;
      bus.$on("forms.corps.select.show", (data) => {
        if (!vm.dialogShow) {
          this.loading = true;
          vm.title = (data && data.title) || vm.title;
          vm.selectedCorp = data.initModel.corp;
          vm.dialogShow = true;
        }
      });
      bus.$on("forms.corps.select.hide", () => {
        this.dialogShow = false;
        this.resizeObserver = null;
      });
    },
    computed: {
      corps() {
        return this.$store.getters["main/corpsFiltered"](this.filterString);
      },
    },
    watch: {
      dialogHeight(value) {
        var containerHeight = value - 72 - 48 - 16 - 48;
        // 48 - header 72 - footer 48 - find input 20 - padding 16 - 2 header
        this.$refs.flex_container.style.height = `${containerHeight}px`;
      },
    },
    mounted() {
      // if (!this.$store.getters["main/corps"].length) {
      //   this.$store.dispatch("main/loadCorps");
      // }
    },
    methods: {
      initSelection() {
        if (this.gridOptions && this.gridOptions.api) {
          this.gridOptions.api.deselectAll();
          if (this.selectedCorp) {
            var node = this.gridOptions.api.getRowNode(this.selectedCorp.id_corp);
            if (node) {
              node.setSelected(true);
              this.gridOptions.api.ensureNodeVisible(node, "middle");
            }
          }
        }
      },
      onGridReady() {
        this.gridColumnApi = this.gridOptions.columnApi;
        var state = this.$store.getters["main/userSettings"]("SelectCorpGridColumnState");
        if (state) this.gridColumnApi.setColumnState(state);
        //this.initSelection();
      },
      observeHeight() {
        var that = this;
        if (!this.resizeObserver) {
          this.resizeObserver = new ResizeObserver(function() {
            that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
          });
          this.resizeObserver.observe(this.$el.children[0]);
        }
      },
      unobserveHeight() {
        this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
        this.resizeObserver = null;
        this.dialogHeight = 0;
      },
      onCommit() {
        if (this.selectedCorp) {
          this.$emit("select-commit", { corp: this.selectedCorp });
          this.dialogShow = false;
        } else this.$message.error($tt("Будь ласка, оберіть один із рядків!"));
      },
      onCancel() {
        this.$emit("select-cancel");
        this.dialogShow = false;
      },
      onOpen() {
        this.observeHeight();
      },
      onOpened() {
        if (!this.$store.getters["main/corps"].length) {
          this.$store
            .dispatch("main/loadCorps")
            .finally(() => {
              this.loading = false;
            })
            .then(() => this.initSelection());
        } else {
          this.loading = false;
          this.initSelection();
        }
      },
      onClose() {
        this.unobserveHeight();
      },
      onGridSelectionChanged() {
        var rows = this.gridOptions.api.getSelectedRows();
        if (rows && rows.length > 0) {
          this.selectedCorp = rows[0];
        } else {
          this.selectedCorp = null;
        }
      },
      onGridColumnsChange() {
        var that = this;
        if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
        this.changeColumnsTimer = setTimeout(() => {
          var state = that.gridColumnApi.getColumnState();
          clearTimeout(that.changeColumnsTimer);
          that.$store.dispatch("main/updateUserSettings", { SelectCorpGridColumnState: state });
        }, 500);
      },
      gridLocale(key, defaultValue) {
        var localized = $tt("grid." + key);
        return localized ? localized : defaultValue;
      },
    },
  };
</script>

<style lang="scss" scope>
  #SelectCorpDialog .header2 {
    background: #fff;
    line-height: 32px;
    border-bottom: 1px solid #ccc !important;
    padding: 0 6px !important;
    align-items: center;
    margin-left: -16px;
    margin-right: -16px;
  }

  #SelectCorpDialog.my-dialog .el-dialog--center .el-dialog__body {
    padding: 16px 16px 0 16px !important;
  }

  .flex_container {
    width: 100%;
  }
</style>
