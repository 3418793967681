<template>
  <div><SelectCorp @select-commit="onSelectCorp" @select-cancel="onSelectCancel" /></div>
</template>

<script>
import { bus } from "@/main";
import SelectCorp from "@/components/forms/SelectCorp";

export default {
  name: "Corps",
  components: { SelectCorp },
  data() {
    return {};
  },

  methods: {
    onSelectCorp(data) {
      if (data && data.corp) {
        this.$store.dispatch("main/setCurrentCorp", { corp: data.corp, router: this.$router });
      } else {
        window.location = "/";
      }
    },
    onSelectCancel(data) {
      window.location = "/";
    },
    show() {
      bus.$emit("forms.corps.select.show", {
        title: this.$i18n.translate("Вибір оточення"),
        initModel: { corp: this.$store.getters["main/corp"]() },
      });
    },
  },
  computed: {},
  mounted() {
    this.show();
  },
};
</script>
